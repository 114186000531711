<template>
  <b-container class='mt-4' fluid>
    <b-row class='mb-4'>
      <b-col cols='6'>
        <h3>Payers</h3>
      </b-col>
    </b-row>
    <b-spinner v-if='loading'></b-spinner>
    <b-row v-else>
      <b-col>
        <table class='table mb-0'>
          <tr>
            <th scope='col'>{{ translations.form.name }}</th>
            <th scope='col'>{{ translations.form.visible_name }}</th>
            <th scope='col'>{{ translations.form.rte_payer_id }}</th>
            <th scope='col'>{{ translations.form.rte_payer_waystar_id }}</th>
            <th scope='col'>{{ translations.form.rte_payer_change_healthcare_id }}</th>
            <th scope='col'>{{ translations.form.rte_payer_mgb_id }}</th>
            <th scope='col'>{{ translations.form.claims_payer_id }}</th>
            <th scope='col' class='btnColWidth'>{{ translations.form.actions }}</th>
          </tr>
          <tbody>
          <tr v-for='(item, index) in apiPayers' :key='index'>
            <td>{{ item.payer_name }}</td>
            <td>{{ item.visible_payer_name }}</td>
            <td>{{ item.rte_payer_id }}</td>
            <td>{{ item.rte_payer_waystar_id }}</td>
            <td>{{ item.rte_payer_change_healthcare_id }}</td>
            <td>{{ item.rte_payer_mgb_id }}</td>
            <td>{{ item.claims_payer_id }}</td>
            <td class='btnColWidth'>
              <b-button
                @click='onEditBtn(item)'
                size='sm'
                variant='secondary'
                class='col-2 mr-1 btnWidth'>
                <feather type='edit-2'></feather>
              </b-button>
              <b-button
                v-if='item.deleted_at==null'
                @click='onDeleteBtn(item)'
                size='sm'
                title='Delete payer'
                variant='danger'
                class='col-2 mr-1 btnWidth'>
                <feather type='trash'></feather>
              </b-button>
            </td>
          </tr>
          <tr>
            <td v-if='apiPayers < 1' colspan='5' class='text-center text-muted'>{{ translations.form.no_payers }}</td>
            <td v-else colspan='5' />
            <td class='btnColWidth'>
              <b-button
                @click='onAddBtn'
                size='sm'
                variant='secondary'
                class='col-2 btnWidth'>
                <feather type='plus'></feather>
              </b-button>
            </td>
          </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
    <div>
      <b-modal
        data-id='payer-form-modal'
        ref='payer-form-modal'
        :title='modalTitle'
        size='xl'
        :ok-title='modalOkBtn'
        @ok='onModalOKBtn'
        static
        :busy='doingButtonRequest'>
        <b-row>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.name'
              :invalid-feedback="handleError('payerForm.payer_name')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.payer_name')">
              <b-form-input v-model='payerForm.payer_name' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.visible_name'
              :invalid-feedback="handleError('payerForm.visible_payer_name')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.visible_payer_name')">
              <b-form-input v-model='payerForm.visible_payer_name' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.rte_payer_id'
              :invalid-feedback="handleError('payerForm.rte_payer_id')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.rte_payer_id')">
              <b-form-input v-model='payerForm.rte_payer_id' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.rte_payer_waystar_id'
              :invalid-feedback="handleError('payerForm.rte_payer_waystar_id')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.rte_payer_waystar_id')">
              <b-form-input v-model='payerForm.rte_payer_waystar_id' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.rte_payer_change_healthcare_id'
              :invalid-feedback="handleError('payerForm.rte_payer_change_healthcare_id')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.rte_payer_change_healthcare_id')">
              <b-form-input v-model='payerForm.rte_payer_change_healthcare_id' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.rte_payer_mgb_id'
              :invalid-feedback="handleError('payerForm.rte_payer_mgb_id')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.rte_payer_mgb_id')">
              <b-form-input v-model='payerForm.rte_payer_mgb_id' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.claims_payer_id'
              :invalid-feedback="handleError('payerForm.claims_payer_id')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.claims_payer_id')">
              <b-form-input v-model='payerForm.claims_payer_id' />
            </b-form-group>
          </b-col>
          <b-col md='6' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.address_line1'
              :invalid-feedback="handleError('payerForm.address_line1')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.address_line1')">
              <b-form-textarea rows='2' v-model='payerForm.address_line1' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.address_city'
              :invalid-feedback="handleError('payerForm.address_city')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.address_city')">
              <b-form-input v-model='payerForm.address_city' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.address_state'
              :invalid-feedback="handleError('payerForm.address_state')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.address_state')">
              <b-form-input
                :formatter='toUpperCase'
                :placeholder='translations.form.address_state_help'
                maxlength='2'
                v-model='payerForm.address_state' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.address_postal_code'
              :invalid-feedback="handleError('payerForm.address_postal_code')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.address_postal_code')">
              <b-form-input
                :placeholder='translations.form.address_postal_code_help'
                maxlength='9'
                v-model='payerForm.address_postal_code' />
            </b-form-group>
          </b-col>
          <b-col md='3' sm='12'>
            <b-form-group
              label-class='p-0'
              :label='translations.form.address_country_code'
              :invalid-feedback="handleError('payerForm.address_country_code')"
              :state="shouldValidateNewPayerForm && isValid('payerForm.address_country_code')">
              <b-form-input
                :formatter='toUpperCase'
                :placeholder='translations.form.address_country_code_help'
                maxlength='2'
                v-model='payerForm.address_country_code' />
            </b-form-group>
          </b-col>
        </b-row>
      </b-modal>
      <b-modal
        data-id='payer-delete-modal'
        ref='payer-delete-modal'
        :title='modalTitle'
        size='x'
        :ok-title='modalOkBtn'
        @ok='onModalOKBtn'
        static
        :busy='doingButtonRequest'>
        <b-row>
          <b-col>
            <p>{{ translations.form.delete_modal_text }}</p>
          </b-col>
        </b-row>
      </b-modal>

      <b-modal ref='system-payer-edit-impact-modal' hide-footer title='Edit Impact'>
        <div class='d-block text-center'>
          <p>{{ editImpactText }}</p>
        </div>
        <b-button class='mt-3' variant='outline-danger' block @click='onImpactModalOKBtn'>Yes</b-button>
        <b-button class='mt-3' variant='outline-danger' block @click='hideEditImpactModal'>No</b-button>
      </b-modal>

    </div>
  </b-container>
</template>

<script>
import {
  required, maxLength, minLength, alpha, integer,
} from 'vuelidate/lib/validators';
import errorHandler from '@/mixins/errorHandler';
import translations from '@/translations';

export default {
  name: 'Payers',
  mixins: [ errorHandler ],
  data() {
    return {
      translations: translations.finance.system_payers,
      loading: true,
      apiPayers: [],
      doingButtonRequest: false,
      shouldValidateNewPayerForm: null, // null instead of false! because "null && false" is null and not false
      payerForm: {},
      editingPayer: null, // payer that is being edited, if any
      deletingPayer: null, // payer that is being deleted, if any
      editImpactText: '',
    };
  },
  validations() {
    return {
      payerForm: {
        payer_name: { required, maxLength: maxLength(100) },
        visible_payer_name: { required, maxLength: maxLength(100) },
        claims_payer_id: { required },
        address_line1: { maxLength: maxLength(100) },
        address_city: { maxLength: maxLength(100) },
        address_state: { alpha, minLength: minLength(2), maxLength: maxLength(2) },
        address_postal_code: { integer, minLength: minLength(9), maxLength: maxLength(9) },
        address_country_code: { alpha, minLength: minLength(2), maxLength: maxLength(2) },
      },
    };
  },
  async beforeMount() {
    this.loading = true;
    await this.loadPayers();
    this.loading = false;
  },
  computed: {
    modalTitle() {
      if (this.editingPayer) {
        return this.translations.form.edit_modal_title;
      }
      if (this.deletingPayer) {
        return this.translations.form.delete_modal_title;
      }
      return this.translations.form.add_modal_title;
    },
    modalOkBtn() {
      if (this.editingPayer) {
        return this.translations.form.edit_button;
      }
      if (this.deletingPayer) {
        return this.translations.form.delete_button;
      }
      return this.translations.form.add_button;
    },
  },
  methods: {
    showEditImpactModal() {
      this.$refs['system-payer-edit-impact-modal'].show();
    },
    hideEditImpactModal() {
      this.$refs['system-payer-edit-impact-modal'].hide();
    },
    async loadPayers() {
      try {
        const payers = await this.$store.dispatch('Financial/getSystemPayers');
        if (payers.data && payers.data.data && payers.data.data.system_payers) {
          this.apiPayers = payers.data.data.system_payers;
        }
        this.emitData();
      } catch (err) {
        let errDetail = err;
        if (err.response && err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `An error occurred fetching payers: ${errDetail}`;
        this.$noty.error(errMsg);
      }
    },
    async addPayer() {
      this.doingButtonRequest = true;
      try {
        const isInvalid = !this.payerForm.rte_payer_id
          && !this.payerForm.rte_payer_waystar_id
          && !this.payerForm.rte_payer_change_healthcare_id
          && !this.payerForm.rte_payer_mgb_id;

        if (isInvalid) {
          this.$noty.error('At least one of: Availity, Waystar, Change Healthcare or MGB must be provided');
          return false;
        }

        const res = await this.$store.dispatch('Financial/addSystemPayer', {
          payerData: this.payerForm,
        });
        this.apiPayers.push(res.data.data);
        this.apiPayers = this.apiPayers.sort((a, b) => a.payer_name.localeCompare(b.payer_name));
        this.resetPayerForm();
        this.$noty.success(this.translations.form.add_success);
        this.emitData();
        this.doingButtonRequest = false;
        return true;
      } catch (err) {
        let errDetail = err;
        if (err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.form.add_error}: ${errDetail}`;
        this.$noty.error(errMsg);
        this.doingButtonRequest = false;
        return false;
      }
    },
    async checkEditPayer() {
      this.doingButtonRequest = true;
      try {
        const res = await this.$store.dispatch('Financial/checkEditSystemPayer', {
          systemPayerId: this.editingPayer.id,
          payerData: this.payerForm,
        });
        this.doingButtonRequest = false;
        return res.data.data;
      } catch (err) {
        let errDetail = err;
        if (err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.form.edit_error}: ${errDetail}`;
        this.$noty.error(errMsg);
        this.doingButtonRequest = false;
      }
      return null;
    },
    async editPayer() {
      this.doingButtonRequest = true;
      try {
        const isInvalid = !this.payerForm.rte_payer_id
          && !this.payerForm.rte_payer_waystar_id
          && !this.payerForm.rte_payer_change_healthcare_id
          && !this.payerForm.rte_payer_mgb_id;

        if (isInvalid) {
          this.$noty.error('At least one of: Availity, Waystar, Change Healthcare or MGB must be provided');
          return false;
        }

        const res = await this.$store.dispatch('Financial/editSystemPayer', {
          systemPayerId: this.editingPayer.id,
          payerData: this.payerForm,
        });

        const index = this.apiPayers.findIndex(p => p.id === this.editingPayer.id);
        if (index !== -1) {
          this.apiPayers[index] = res.data.data;
        }
        this.editingPayer = null;
        this.resetPayerForm();
        this.$noty.success(this.translations.form.edit_success);
        this.emitData();
        this.doingButtonRequest = false;
        return true;
      } catch (err) {
        let errDetail = err;
        if (err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.form.edit_error}: ${errDetail}`;
        this.$noty.error(errMsg);
        this.doingButtonRequest = false;
        return false;
      }
    },
    async deletePayer() {
      this.doingButtonRequest = true;
      try {
        await this.$store.dispatch('Financial/deleteSystemPayer', {
          systemPayerId: this.deletingPayer.id,
        });

        this.apiPayers = this.apiPayers.filter(p => p.id !== this.deletingPayer.id);
        this.editingPayer = null;
        this.deletingPayer = null;
        this.$noty.success(this.translations.form.delete_success);
        this.emitData();
        this.doingButtonRequest = false;
        return true;
      } catch (err) {
        let errDetail = err;
        if (err.response.data && err.response.data.error && err.response.data.error.detail) {
          errDetail = err.response.data.error.detail;
        }
        const errMsg = `${this.translations.form.delete_error}: ${errDetail}`;
        this.$noty.error(errMsg);
        this.doingButtonRequest = false;
        return false;
      }
    },
    loadSystemPayerForm(systemPayer) {
      this.payerForm = {
        payer_name: systemPayer.payer_name,
        visible_payer_name: systemPayer.visible_payer_name,
        rte_payer_id: systemPayer.rte_payer_id,
        rte_payer_waystar_id: systemPayer.rte_payer_waystar_id,
        rte_payer_change_healthcare_id: systemPayer.rte_payer_change_healthcare_id,
        rte_payer_mgb_id: systemPayer.rte_payer_mgb_id,
        claims_payer_id: systemPayer.claims_payer_id,
        address_line1: systemPayer.address_line1,
        address_city: systemPayer.address_city,
        address_state: systemPayer.address_state,
        address_postal_code: systemPayer.address_postal_code,
        address_country_code: systemPayer.address_country_code,
      };
    },
    resetPayerForm() {
      this.payerForm = {
        payer_name: '',
        visible_payer_name: '',
        rte_payer_id: '',
        rte_payer_waystar_id: '',
        rte_payer_change_healthcare_id: '',
        rte_payer_mgb_id: '',
        claims_payer_id: '',
        address_line1: '',
        address_city: '',
        address_state: '',
        address_postal_code: '',
        address_country_code: '',
      };
    },
    toUpperCase(value) {
      if (value) {
        return value.toUpperCase();
      }
      return value;
    },
    onAddBtn() {
      this.editingPayer = null;
      this.deletingPayer = null;
      this.shouldValidateNewPayerForm = null;
      this.resetPayerForm();
      this.$refs['payer-form-modal'].show();
    },
    onEditBtn(systemPayer) {
      this.editingPayer = systemPayer;
      this.deletingPayer = null;
      this.shouldValidateNewPayerForm = null;
      this.loadSystemPayerForm(systemPayer);
      this.$refs['payer-form-modal'].show();
    },
    onDeleteBtn(systemPayer) {
      this.deletingPayer = systemPayer;
      this.editingPayer = null;
      this.shouldValidateNewPayerForm = null;
      this.loadSystemPayerForm(systemPayer);
      this.$refs['payer-delete-modal'].show();
    },
    async onModalOKBtn(bvModalEvent) {
      bvModalEvent.preventDefault(); // manually close after success

      if (this.deletingPayer) {
        const result = await this.deletePayer();
        if (result) {
          this.$refs['payer-delete-modal'].hide();
        }
        return;
      }

      if (this.editingPayer) {
        const impact = await this.checkEditPayer();
        if (impact && impact.length > 0) {
          this.editImpactText = `Your edit is going to impact ${impact.length} clients, do you want to continue?`;
          this.showEditImpactModal();
          return;
        }
      }

      const result = this.doEditOrAddRequest();
      if (result) {
        this.$refs['payer-form-modal'].hide();
      }
    },
    async onImpactModalOKBtn(bvModalEvent) {
      bvModalEvent.preventDefault(); // manually close after success

      this.hideEditImpactModal();

      const result = this.doEditOrAddRequest();
      if (result) {
        this.$refs['payer-form-modal'].hide();
      }
    },
    async doEditOrAddRequest() {
      this.shouldValidateNewPayerForm = true;
      if (this.$v.payerForm.$invalid) {
        return false;
      }
      return this.editingPayer ? this.editPayer() : this.addPayer();
    },
    emitData() {
      this.$emit('data', JSON.parse(JSON.stringify(this.apiPayers)));
    },
  },
};
</script>
<style lang='scss' scoped>
.btnWidth {
  min-width: 60px;
  max-width: 60px;
}

.btnColWidth {
  width: 150px;
}
</style>
